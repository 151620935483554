import { Button, Card, Col, DatePicker, Modal, Row, Select, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { apiCall } from "../../API/RestApi";
import { currencyFormat } from "../../Utils/formatCurrency";
import { useReactToPrint } from 'react-to-print';
import FormatPrintDailyReport from "./FormatPrintDailyReport";
import { useSelector } from "react-redux";
import { dateFormat } from "../../Utils/formatDate";
import { formatNumber } from "../../Utils/formatNumber";
import { modalError } from "../../Component/Modal/ModalLayout";
const { RangePicker } = DatePicker;

function OutletReport() {
    const componentRef = useRef();
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);
    const [dataTotalTransaction, setDataTotalTransaction] = useState(null);
    const [dataAll, setDataAll] = useState([]);
    const [dataModal, setDataModal] = useState();
    const [loading, setLoading] = useState(false);

    const [outlet, setOutlet] = useState([]);

    const [valueOutlet, setValueOutlet] = useState();
    const [valueDate, setValueDate] = useState();

    const [modalReport, setModalReport] = useState(false);
    const [modalPrint, setModalPrint] = useState(false);

    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const titlePage = 'Laporan Harian';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Tanggal',
            dataIndex: 'created_at',
            key: "created_at",
            sorter: true,
            render: (id, record, index) => {
                if (record.transactions !== undefined)
                    if (record.transactions.length > 0)
                        return dateFormat(record.transactions[record.transactions.length - 1].created_at);
                    else
                        return dateFormat(record.created_at);
                else {
                    return '-'
                }
            },
        },
        {
            title: 'Outlet',
            dataIndex: 'outlet_name',
            key: "outlet_name",
            sorter: true,
        },
        {
            title: 'Total Transaksi',
            dataIndex: 'total_transaction',
            key: "total_transaction",
            sorter: true,
            render: (id, record, index) => {
                if (record.transaction !== null)
                    return record.transactions.length + ' Transaksi';
                else
                    return '0 Transaksi';
            },
        },
        {
            title: 'Total Pendapatan(Rp)',
            dataIndex: 'transaction_code',
            key: "transaction_code",
            sorter: true,
            render: (id, record, index) => {
                if (record.transaction !== null) {
                    var total = 0;
                    record.transactions.forEach(item => {
                        total += Number(item.grand_total);
                    });
                    return currencyFormat(total);
                } else {
                    return 'Rp. 0';
                }
            },
        },
        {
            title: 'Point',
            dataIndex: 'point_point',
            key: "point_point",
            sorter: true,
            render: (id, record, index) => {
                if (record.transaction !== null) {
                    var total_point = 0;
                    if (record.transactions.length > 0) {
                        for (let i = 0; i < record.transactions.length; i++) {
                            if (record.transactions[i].point !== null)
                                total_point += Number(record.transactions[i].point.point);
                        }
                    }
                    return formatNumber(total_point) + ' point';
                } else {
                    return '0 point';
                }
            },
        },
        {
            title: 'Point Redeem',
            dataIndex: 'point_redeem',
            key: "point_redeem",
            sorter: true,
            render: (id, record, index) => {
                if (record.transaction !== null) {
                    var point_redeem = 0;
                    if (record.transactions.length > 0) {
                        for (let i = 0; i < record.transactions.length; i++) {
                            if (record.transactions[i].point_redeem !== undefined)
                                point_redeem += Number(record.transactions[i].point_redeem);
                        }
                    }
                    return point_redeem + ' point';
                } else {
                    return '0 point';
                }
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal(record)}>
                            <b style={{ marginLeft: 5 }}>Detail</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        var vOutlet = 'outletId=' + valueOutlet;
        var vDate = '';
        if (valueDate !== null && valueDate !== undefined) {
            vDate = 'startDate=' + valueDate[0] + '&endDate=' + valueDate[1];
        }
        var url = '/outlettransaction?page=' + page.pagination.current;
        var url2 = '/outlettransaction?nopage=true';
        if (valueOutlet !== undefined && valueDate !== undefined) {
            url = url + '&' + vOutlet + '&' + vDate;
            url2 = url2 + '&' + vOutlet + '&' + vDate;
        } else if (valueOutlet !== undefined) {
            url = url + '&' + vOutlet;
            url2 = url2 + '&' + vOutlet;
        } else if (valueDate !== undefined) {
            url = url + '&' + vDate;
            url2 = url2 + '&' + vDate;
        }
        getApi("GET", url, 'outlettransaction');
        getApi("GET", url2, 'otnopage');
        if (outlet.length === 0) {
            // getApi('GET', '/outlet?nopage=true', 'outlet');
            getApi('GET', '/useroutlet?nopage=true', 'useroutlet');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page)]);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                if (type === 'outlettransaction') {
                    setDataTotalTransaction(res.total);
                    for (let i = 0; i < res.data.rows.length; i++) {
                        var item = res.data.rows[i];
                        res.data.rows[i].key = item.outlet_id
                    }
                    setData(res.data.rows);
                    setPage({
                        ...page,
                        pagination: {
                            ...page.pagination,
                            total: res.data.count
                        }
                    });
                    setLoading(false);
                } else if (type === 'transaction') {
                    if (res.data.count > 0) {
                        var count_transaction = res.data.count;
                        var count_product = 0;
                        res.data.rows.forEach(item => {
                            item.transaction_products.forEach(item2 => {
                                count_product += Number(item2.qty);
                            });
                        });

                        var detail_data = [
                            {
                                label: 'Kode Outlet',
                                value: res.data.rows[0].outlet.outlet_code
                            },
                            {
                                label: 'Nama Outlet',
                                value: res.data.rows[0].outlet.outlet_name
                            },
                            {
                                label: 'Total Transaksi',
                                value: count_transaction + ' Transaksi'
                            },
                            {
                                label: 'Total Produk Terjual',
                                value: count_product + ' Produk'
                            },
                        ]

                        var data_outlet = {
                            detail: detail_data,
                            transaction: res.data.rows,
                            total: res.total
                        }

                        setDataModal(data_outlet);
                        setModalReport(true);
                    } else {
                        modalError('Outlet ini tidak ada transaksi');
                    }
                    setLoading(false);
                } else if (type === 'otnopage') {
                    setDataAll(res.data.rows);
                    setLoading(false);

                } else if (type === 'useroutlet') {
                    var row = res.data;
                    if (row.length > 0) {
                        var json = [];
                        for (let i = 0; i < row.length; i++) {
                            if (row[i].outlet !== null) {
                                json = [...json, {
                                    value: row[i].outlet.outlet_id,
                                    label: row[i].outlet.outlet_name
                                }];
                            }
                        }
                        setOutlet(json);
                        setLoading(false);
                    } else {
                        getApi('GET', '/outlet?nopage=true', 'outlet');
                    }
                } else if (type === 'outlet') {
                    var o = [];
                    for (let i = 0; i < res.data.length; i++) {
                        o = [...o, {
                            value: res.data[i].outlet_id,
                            label: res.data[i].outlet_name
                        }];
                    }
                    setOutlet(o);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const findSearch = () => {
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const openModal = (ev) => {
        var vDate = '';
        if (valueDate !== null && valueDate !== undefined) {
            vDate = '&startDate=' + valueDate[0] + '&endDate=' + valueDate[1];
        }
        getApi('GET', 'transaction?page=1&outletId=' + ev.outlet_id + vDate, 'transaction');
    }

    const goPrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // const CustomerColumns = [
    //     {
    //         title: 'Nama Customer',
    //         dataIndex: 'name',
    //         sorter: true,
    //         key: "name",
    //         render: (id, record, index) => {
    //             if (record.customer !== null)
    //                 return record.customer.name
    //         }
    //     },
    //     {
    //         title: 'Nomor HP',
    //         dataIndex: 'phone',
    //         key: "phone",
    //         sorter: true,
    //         render: (id, record, index) => {
    //             if (record.customer !== null)
    //                 return record.customer.phone
    //         },
    //     },
    // ]

    const cardStyle = {
        borderRadius: '10px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        minHeight: '100px',
        color: '#fff',
        textAlign: 'center',
        fontSize: '18px',
        padding: '0px'
    };

    const colors = {
        totalTunai: '#3498db', // Blue
        totalEDCBCA: '#1abc9c', // Green
        totalEDCBRI: '#3498db', // Yellow
        totalEDCMandiri: '#f39c12', // Yellow
        totalPenjualan: '#e74c3c', // Red
        background: '#ecf0f1' // Light grey
    };
    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            <h3>Laporan Outlet</h3>{
                dataTotalTransaction !== null && (
                    <Row gutter={[16, 16]} style={{ marginBottom: 15, marginLeft: 15 }}>
                        <Col span={6}>
                            <Card
                                title="Total Tunai"
                                bordered={false}
                                style={{ ...cardStyle, backgroundColor: colors.totalTunai }}
                            >
                                <div>
                                    {currencyFormat(dataTotalTransaction.total_tunai.total)}
                                </div>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card
                                title="Total EDC BCA"
                                bordered={false}
                                style={{ ...cardStyle, backgroundColor: colors.totalEDCBCA }}
                            >
                                <div>
                                    {currencyFormat(dataTotalTransaction.total_edc_bca.total)}
                                </div>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card
                                title="Total EDC BRI"
                                bordered={false}
                                style={{ ...cardStyle, backgroundColor: colors.totalEDCBRI }}
                            >
                                <div>
                                    {currencyFormat(dataTotalTransaction.total_edc_bri.total)}
                                </div>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card
                                title="Total EDC Mandiri"
                                bordered={false}
                                style={{ ...cardStyle, backgroundColor: colors.totalEDCMandiri }}
                            >
                                <div>
                                    {currencyFormat(dataTotalTransaction.total_edc_mandiri.total)}
                                </div>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card
                                title="Total Penjualan"
                                bordered={false}
                                style={{ ...cardStyle, backgroundColor: colors.totalPenjualan }}
                            >
                                <div style={{ fontWeight: "bold" }}>
                                    {currencyFormat(
                                        Number(dataTotalTransaction.total_tunai.total) +
                                        Number(dataTotalTransaction.total_edc_bca.total) +
                                        Number(dataTotalTransaction.total_edc_mandiri.total) +
                                        Number(dataTotalTransaction.total_edc_bri.total)
                                    )}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                )}
            <div >
                <h3>Form Pencarian</h3>
                <Select
                    mode="multiple"
                    showSearch
                    style={{
                        width: 300,
                        marginBottom: 10
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder="Pilih Outlet"
                    onChange={setValueOutlet}
                    options={outlet} />
                <br />
                <RangePicker
                    style={{
                        width: 300,
                        marginBottom: 10
                    }}
                    onChange={(ev, date) => setValueDate(date)}
                />

                <br />
                <Button style={{ marginBottom: 10 }} type="primary" onClick={() => findSearch()}>
                    <b style={{ marginLeft: 5 }}>Cari</b>
                </Button>
                <Button style={{ marginBottom: 10, marginLeft: 10 }} onClick={() => setModalPrint(true)}>
                    <b style={{ marginLeft: 5 }}>Cetak Data</b>
                </Button>
            </div>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            // expandable={{
            //     expandedRowRender: (record) => (
            //         <Table
            //             size="middle"
            //             columns={CustomerColumns}
            //             dataSource={record.transactions}
            //         />
            //     ),
            //     // rowExpandable: (record) => (record.transactions !== null ? record.transactions.length : 0) > 0,
            // }}
            />

            <Modal
                title={<div style={{ fontSize: 20, fontWeight: 'bold', color: '#333' }}>Detail Report</div>}
                width={800}
                open={modalReport}
                footer={null}
                onCancel={() => setModalReport(false)}
                bodyStyle={{ padding: '20px 30px' }}
            >
                {dataModal !== null && dataModal !== undefined && (
                    <>
                        {/* General Details */}
                        <div style={{ marginBottom: 20 }}>
                            {dataModal.detail.length > 0 &&
                                dataModal.detail.map((key, i) => (
                                    <Row key={i} gutter={16} style={{ marginBottom: 10 }}>
                                        <Col span={8}>
                                            <div style={{ fontSize: 14, color: '#888', fontWeight: '500' }}>{key.label}</div>
                                        </Col>
                                        <Col span={1}>
                                            <div>:</div>
                                        </Col>
                                        <Col span={15}>
                                            <div style={{ fontSize: 14, color: '#333' }}>{key.value}</div>
                                        </Col>
                                    </Row>
                                ))}
                        </div>

                        {/* Sales Summary */}
                        <div style={{ borderBottom: '2px solid #EEE', paddingBottom: 10 }}>
                            <h3 style={{ fontSize: 18, fontWeight: 'bold', color: '#555', marginBottom: 15 }}>
                                Total Penjualan Outlet
                            </h3>

                            {['TUNAI', 'EDC BCA', 'EDC MANDIRI', 'EDC BRI'].map((method, index) => (
                                <Row key={index} gutter={16} style={{ marginBottom: 8 }}>
                                    <Col span={8}>
                                        <div style={{ fontSize: 14, fontWeight: '600', color: '#444' }}>{method}</div>
                                    </Col>
                                    <Col span={1}>
                                        <div>:</div>
                                    </Col>
                                    <Col span={15}>
                                        <div style={{ fontSize: 14, fontWeight: '600', color: '#2D85C7' }}>
                                            {currencyFormat(dataModal.total[`total_${method.toLowerCase().replace(' ', '_')}`].total)}
                                        </div>
                                    </Col>
                                </Row>
                            ))}

                            <hr style={{ marginTop: 15, marginBottom: 15, borderColor: '#DDD' }} />

                            <Row gutter={16}>
                                <Col span={8}>
                                    <div style={{ fontSize: 16, fontWeight: '700', color: '#333' }}>TOTAL PENJUALAN</div>
                                </Col>
                                <Col span={1}>
                                    <div>:</div>
                                </Col>
                                <Col span={15}>
                                    <div style={{ fontSize: 16, fontWeight: '700', color: '#DC143C' }}>
                                        {currencyFormat(
                                            Number(dataModal.total.total_tunai.total) +
                                            Number(dataModal.total.total_edc_bca.total) +
                                            Number(dataModal.total.total_edc_bri.total) +
                                            Number(dataModal.total.total_edc_mandiri.total)
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {/* Transaction List */}
                        <div style={{ marginTop: 30 }}>
                            <h3 style={{ fontSize: 18, fontWeight: 'bold', color: '#555', marginBottom: 15 }}>
                                Daftar Transaksi Outlet
                            </h3>
                            {dataModal.transaction.length > 0 &&
                                dataModal.transaction.map((key, i) => (
                                    <Card
                                        key={i}
                                        style={{
                                            marginBottom: 12,
                                            borderRadius: 10,
                                            padding: '15px',
                                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        <Row gutter={16}>
                                            <Col span={8}>
                                                <div style={{ fontSize: 13, color: '#888' }}>Kode Transaksi</div>
                                                <div style={{ fontSize: 13, color: '#888' }}>Total Harga</div>
                                                <div style={{ fontSize: 13, color: '#888' }}>Point</div>
                                            </Col>
                                            <Col span={16}>
                                                <div style={{ fontSize: 14, fontWeight: 'bold', color: '#333' }}>
                                                    : {key.transaction_code}
                                                </div>
                                                <div style={{ fontSize: 14, color: '#DC143C' }}>
                                                    : {currencyFormat(Number(key.grand_total))}
                                                </div>
                                                <div style={{ fontSize: 13 }}>
                                                    : {key.point !== null ? formatNumber(key.point.point) : 0}
                                                </div>
                                            </Col>
                                        </Row>

                                        <hr style={{ borderColor: '#EEE', marginTop: 10, marginBottom: 10 }} />

                                        {/* Transaction Products */}
                                        <Row gutter={16}>
                                            <Col span={16}>
                                                <div style={{ fontSize: 14, fontWeight: 'bold', color: '#333' }}>Nama Produk</div>
                                            </Col>
                                            <Col span={2}>
                                                <div style={{ fontSize: 14, fontWeight: 'bold', color: '#333' }}>Qty</div>
                                            </Col>
                                            <Col span={6} style={{ textAlign: 'right' }}>
                                                <div style={{ fontSize: 14, fontWeight: 'bold', color: '#333' }}>Total</div>
                                            </Col>
                                        </Row>

                                        {key.transaction_products.map((key2, i2) => (
                                            <Row key={i2} gutter={16} style={{ paddingTop: 5, paddingBottom: 5 }}>
                                                <Col span={16}>
                                                    <div style={{ fontSize: 13, color: '#666' }}>{key2.product.product_name}</div>
                                                </Col>
                                                <Col span={2}>
                                                    <div style={{ fontSize: 13, color: '#666' }}>{key2.qty}</div>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'right' }}>
                                                    <div style={{ fontSize: 13, color: '#DC143C' }}>{currencyFormat(key2.subtotal)}</div>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Card>
                                ))}
                        </div>
                    </>
                )}
            </Modal>


            <Modal title="Preview Data" width={1000} open={modalPrint} footer={null} onCancel={() => setModalPrint(false)}>
                <Button style={{ marginBottom: 10, marginLeft: 10 }} onClick={() => goPrint(true)}>
                    <b style={{ marginLeft: 5 }}>Cetak Sekarang</b>
                </Button>
                <FormatPrintDailyReport data={dataAll} ref={componentRef} />
            </Modal>
        </div>
    );
}


export default OutletReport;