import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import { posPriceDiscount, posPriceDiscountPercent } from '../../Utils/POS/posPrice';
import { currencyFormat } from '../../Utils/formatCurrency';
import { Image, List, Typography, Row, Col, Modal, Carousel } from 'antd';
import { urlProduct } from '../../Utils/globalUrl';
import './DisplayScreen.css'; // Assuming you have a CSS file for custom styles
import { apiCall } from '../../API/RestApi';
import { useSelector } from 'react-redux';

const { Title, Text } = Typography;
const socket = io('https://pos-api.boldestore.com', {
    reconnection: true, // Enable reconnection
    reconnectionAttempts: Infinity, // Retry forever
    reconnectionDelay: 1000, // Initial delay in ms
    reconnectionDelayMax: 5000, // Maximum delay in ms
    randomizationFactor: 0.5 // Randomization factor
});
// const socket = io('http://localhost:8010');

const DisplayScreen = () => {
    const { outletId } = useParams(); // Mengambil outletId dari URL
    const [transactions, setTransactions] = useState([]);
    const [total, setTotal] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [voucher, setVoucher] = useState();
    const [voucherPrice, setVoucherPrice] = useState(0);
    const [point, setPoint] = useState(0);
    const [usePoint, setUsePoint] = useState(false);
    const [useVoucher, setUseVoucher] = useState(false);
    const listRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [display, setDisplay] = useState(null);
    const token = useSelector((state) => state.user.token);


    useEffect(() => {
        getApi('GET', '/display?outlet_id=' + outletId, 'display');
        socket.emit('joinRoom', outletId);
        socket.on('updateDisplay', (data) => {
            setPaymentMethod(data.payment_method);
            setTransactions(data.transactions);
            setTotal(data.total);
            setPoint(Number(data.point));
            setVoucherPrice(data.voucher);
            setVoucher(data.vcp);
            setUsePoint(data.usePoint);
            setUseVoucher(data.useVoucher);
            if (data.pay === true) {
                setShowModal(true);
                setTimeout(() => {
                    setShowModal(false);
                }, 8000);
            }
        });

        socket.on('resetDisplay', () => {
            console.log('masuk')
            setPaymentMethod(null);
            setTransactions([]);
            setTotal(0);
            setPoint(0);
            setVoucherPrice(0);
            setVoucher(null);
            setUsePoint(false);
            setUseVoucher(false);
        });

        return () => {
            socket.off('updateDisplay');
            socket.off('resetDisplay');
        };
    }, [outletId]);

    useEffect(() => {
        if (listRef.current) {
            scrollToBottom();
        }
    }, [transactions]);

    const getApi = (method, url, type) => {
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'display':
                        setDisplay(res.data.rows[0]);
                        break;
                    default:
                        break;
                }
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const scrollToBottom = () => {
        listRef.current.scrollTop = listRef.current.scrollHeight;
    };

    return (
        <div className="display-container">
            <Modal open={showModal} footer={null} onClose={() => setShowModal(false)}>
                <div style={{ textAlign: "center" }}>
                    <h1>Pembayaran Berhasil!</h1>
                    <Image src='/assets/done.gif' width={300} preview={false} />
                </div>
            </Modal>
            <Row>
                <Col span={12} style={{ paddingRight: 10, textAlign: "center" }}>
                    <video width="100%" autoPlay loop={false}>
                        <source src='./assets/video/hallowen2.mp4' type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                    {
                        display ?
                            <Image
                                width={'100%'}
                                preview={false}
                                src={display.banner}
                            />
                            :
                            <Image
                                width={'100%'}
                                preview={false}
                                src='/assets/apps.png'
                            />
                    }
                    <Carousel autoplay>
                        {
                            display ?
                                display.banner_slides.map((slide, index) => {
                                    return (
                                        <div key={index}>
                                            <Image
                                                width={'100%'}
                                                preview={false}
                                                src={slide.banner_slide}
                                            />
                                        </div>
                                    )
                                })
                                :

                                <Image
                                    width={'100%'}
                                    preview={false}
                                    src='/assets/slide1.png'
                                />
                        }
                    </Carousel>
                </Col>
                <Col span={12}>
                    <div style={{
                        marginBottom: 10, padding: 10, backgroundColor: "#FFF", borderRadius: 10
                    }}>
                        <Row justify="center" align="middle" style={{ margin: 0, padding: 0 }}>
                            <Col span={6} >
                                <Image
                                    width={'30%'}
                                    height={'auto'}
                                    preview={false}
                                    src={'/assets/logo_white.png'}
                                />
                            </Col>
                            <Col span={18} style={{ textAlign: 'left' }}>
                                <Title level={5} style={{ padding: 0, margin: 0 }} className="title">Daftar Produk</Title>
                            </Col>
                        </Row>
                        <div ref={listRef} className="list-container" style={{ height: 450, overflowY: 'auto' }}>
                            <List
                                className="product-list"
                                grid={{ column: 1 }}
                                dataSource={transactions}
                                renderItem={(item) => (
                                    <List.Item style={{ marginBottom: 5 }}>
                                        <div style={{ border: '1px solid #E5E5E5', borderRadius: 10, padding: 5 }}>
                                            <Row>
                                                <Col span={3}>
                                                    <Image
                                                        className="product-image"
                                                        preview={false}
                                                        src={item.image != null ? urlProduct + item.image : '/assets/photo_empty.png'}
                                                        alt={item.product.product_name}
                                                        width={'70%'}
                                                    />
                                                </Col>
                                                <Col span={21}>
                                                    <div className="product-info">
                                                        <Text strong className="product-name">
                                                            {item.product.product_name} {item.variant_name && `(${item.variant_name})`}
                                                        </Text>
                                                        <div className="product-details">
                                                            {item.edit_discount_percent !== undefined ? (
                                                                <div className="discount-badge">
                                                                    <Text className="discount-text">{item.edit_discount_percent}%</Text>
                                                                </div>
                                                            ) : (
                                                                posPriceDiscountPercent(item) !== null && (
                                                                    <div className="discount-badge">
                                                                        <Text className="discount-text">{posPriceDiscountPercent(item)}</Text>
                                                                    </div>
                                                                )
                                                            )}
                                                            <Text className="product-qty">Qty: {item.qty}</Text>
                                                            <Text className="product-price">
                                                                {item.edit_price !== undefined ? currencyFormat(item.edit_price) : posPriceDiscount(item)}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </List.Item>
                                )}
                            />

                        </div>
                    </div>
                    {
                        usePoint === true || useVoucher === true ?
                            <div style={{
                                marginBottom: 10, padding: 10, backgroundColor: "#FFF", borderRadius: 10
                            }}>
                                {
                                    usePoint === true &&
                                    <Row>
                                        <Col span={12} style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <Title style={{ margin: 0, padding: 0 }} level={5}>Point</Title>
                                        </Col>
                                        <Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Title style={{ margin: 0, padding: 0 }} level={5}>{currencyFormat(point)}</Title>
                                        </Col>
                                    </Row>
                                }
                                {
                                    useVoucher === true &&
                                    <Row>
                                        <Col span={12} style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <Title style={{ margin: 0, padding: 0 }} level={5}>Voucher</Title>
                                        </Col>
                                        <Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {
                                                Number(voucher.discount_percent) > 0 ?
                                                    <Title style={{ margin: 0, padding: 0 }} level={5}>{voucher.discount_percent}%</Title>
                                                    :
                                                    <Title style={{ margin: 0, padding: 0 }} level={5}>{currencyFormat(voucherPrice)}</Title>
                                            }
                                        </Col>
                                    </Row>
                                }
                            </div>
                            :
                            null
                    }
                    {
                        paymentMethod !== null ?
                            <div style={{
                                marginBottom: 10, padding: 10, backgroundColor: "#FFF", borderRadius: 10
                            }}>
                                <Row>
                                    <Col span={12} style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <Title style={{ margin: 0, padding: 0 }} level={5}>Metode Pembayaran</Title>
                                    </Col>
                                    <Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Title style={{ margin: 0, padding: 0 }} level={5}>{paymentMethod?.name}</Title>
                                    </Col>
                                </Row>
                            </div>
                            :
                            null
                    }
                    <div style={{
                        backgroundColor: "#FFF", borderRadius: 10
                    }}>
                        <div>
                            <Title style={{ margin: 0, paddingTop: 10, paddingLeft: 10, paddingBottom: 10 }} level={5}>Grand Total</Title>
                            <Title level={2} style={{ background: "#E5E5E5", textAlign: 'right', padding: 5, borderRadius: 5, margin: 0 }}> {currencyFormat(total)}</Title>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default DisplayScreen;
